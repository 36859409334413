import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/OcBe83FFvxk">
    <SEO title="How Great Thou Art - Greatest Hits" />
  </Layout>
)

export default SermonPost
